import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { DEFAULT_PATH_BEFORE_LOGIN, PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  LoginPage,
  PageProfile,
  RequestsAllPage,
  RegisterPage,
  OrganizationsEditPage,
  OrganizationsAddPage,
  OrganizationsAllPage,
  LocationsAll,
  LocationsAddPage,
  LocationsEditPage,
  ProviderCertificatesAllPage,
  ProviderCertificatesAddPage,
  PageProviderProfile,
  ProviderRequestViewPage,
  RequestsAddPage,
  RequestViewPage,
  ForgotPasswordPage,
  ProviderRequestsAllPage,
  CertificatesAllPage,
  CertificatesAddPage,
  OrganizationProfile,
  PageProviderInfo,
  DashboardPage,
  ProviderDashboardPage,
  SellOrderPage,
  BuyOrderPage,
  OrdersPage,
  OrderLogsPage,
  OrdersCompletedPage,
  SpacesAllPage,
  SpacesAddPage,
  SpacesEditPage,
  SpaceViewPage,
  FileManagerPage,
  LeadsPage,
  OrganizationViewPage,
  LocationViewPage,
  VehicleAllPage,
  VehicleViewPage,
  ChargerAllPage,
  ChargerViewPage,
  VehicleDetailsPage,
  ChargerDetailsPage,
  SpaceAddSuccessPage,
  SpaceAddErrorPage,
  MeterAllPage,
  MeterDetailsPage,
  PVAllPage,
  PVDetailsPage,
  PublicChargersAllPage,
} from "./elements";
import VerifyPage from "src/pages/Verify";
import ChangePasswordPage from "src/pages/ChangePasswordPage";
import RoleBasedGuard from "src/auth/RoleBasedGuard";
import { Role } from "src/enum/roles";
import OrganizationProfileLayout from "src/layouts/public/organizationProfile";
import config from "src/config";
import _ from "lodash";
import { SpaceDataTypes } from "src/@types/space";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: _.compact([
        {
          element: <Navigate to={DEFAULT_PATH_BEFORE_LOGIN} replace />,
          index: true,
        },
        {
          path: "/public",
          children: [
            {
              element: <Navigate to={DEFAULT_PATH_BEFORE_LOGIN} replace />,
              index: true,
            },
            {
              path: "organization/:id",
              element: <OrganizationProfileLayout />,
            },
          ],
        },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        config.SERVICE.registrationEnabled
          ? {
              path: "register",
              element: (
                <GuestGuard>
                  <RegisterPage />
                </GuestGuard>
              ),
            }
          : undefined,
        {
          path: "forgot-password",
          element: (
            <GuestGuard>
              <ForgotPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ChangePasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: "verify",
          element: (
            <GuestGuard>
              <VerifyPage />
            </GuestGuard>
          ),
        },
      ]),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "profile",
          element: (
            <RoleBasedGuard
              hasContent
              roles={[Role.SuperAdmin, Role.StandardUser]}
            >
              <PageProfile />
            </RoleBasedGuard>
          ),
        },
        {
          path: "general",
          children: [
            {
              element: <Navigate to="/dashboard/general/dashboard" replace />,
              index: true,
            },
            {
              path: "dashboard",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <DashboardPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "provider-dashboard",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderDashboardPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "requests",
          children: [
            {
              element: <Navigate to="/dashboard/requests/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <RequestsAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create/:goAmount",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <RequestsAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <RequestViewPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "organizations",
          children: [
            {
              element: <Navigate to="/dashboard/organizations/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <OrganizationsAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <OrganizationsAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <OrganizationsEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/profile",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <OrganizationProfile />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <OrganizationViewPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "spaces",
          children: [
            {
              element: <Navigate to="/dashboard/spaces/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpaceViewPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create/success",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpaceAddSuccessPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create/error",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpaceAddErrorPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "vehicles",
          children: [
            {
              element: <Navigate to="/dashboard/vehicles/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <VehicleAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAddPage spaceDataType={SpaceDataTypes.EV} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesEditPage spaceDataType={SpaceDataTypes.EV} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <VehicleViewPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/vehicle/:tabId?",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <VehicleDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "public-chargers",
          children: [
            {
              element: (
                <Navigate to="/dashboard/public-chargers/list" replace />
              ),
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <PublicChargersAllPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "chargers",
          children: [
            {
              element: <Navigate to="/dashboard/chargers/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <ChargerAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAddPage spaceDataType={SpaceDataTypes.EVSE} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesEditPage spaceDataType={SpaceDataTypes.EVSE} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <ChargerViewPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/charger/:tabId?",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <ChargerDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "meters",
          children: [
            {
              element: <Navigate to="/dashboard/meters/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <MeterAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAddPage spaceDataType={SpaceDataTypes.CONSUMPTION} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesEditPage spaceDataType={SpaceDataTypes.CONSUMPTION} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/meter/:tabId?",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <MeterDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "pvs",
          children: [
            {
              element: <Navigate to="/dashboard/pvs/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <PVAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesAddPage spaceDataType={SpaceDataTypes.PRODUCTION} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <SpacesEditPage spaceDataType={SpaceDataTypes.PRODUCTION} />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/pv/:tabId?",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <PVDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "file-manager",
          children: [
            {
              element: <Navigate to="/dashboard/file-manager/files" replace />,
              index: true,
            },
            {
              path: "files",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <FileManagerPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "locations",
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/locations/organization/:orgId/list"
                  replace
                />
              ),
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <LocationsAll />
                </RoleBasedGuard>
              ),
            },
            {
              path: "organization/:orgId/create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <LocationsAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <LocationsAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "organization/:orgId/location/:id/edit",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <LocationsEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "organization/:orgId/location/:id/view/:tabId?",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <LocationViewPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "certificates",
          children: [
            {
              element: <Navigate to="/dashboard/certificates/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <CertificatesAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.StandardUser]}
                >
                  <CertificatesAddPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "provider-certificates",
          children: [
            {
              element: (
                <Navigate to="/dashboard/provider-certificates/list" replace />
              ),
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderCertificatesAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderCertificatesAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "request/:requestId/create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderCertificatesAddPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "leads",
          children: [
            {
              element: <Navigate to="/dashboard/leads/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <LeadsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "orders",
          children: [
            {
              element: <Navigate to="/dashboard/orders/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <OrdersPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "logs",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <OrderLogsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "completed",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <OrdersCompletedPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "sell/create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <SellOrderPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "buy/create",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <BuyOrderPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "provider-requests",
          children: [
            {
              element: (
                <Navigate to="/dashboard/provider-requests/list" replace />
              ),
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderRequestsAllPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[Role.SuperAdmin, Role.EnergyProvider]}
                >
                  <ProviderRequestViewPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "provider-profile",
          element: (
            <RoleBasedGuard hasContent roles={[Role.EnergyProvider]}>
              <PageProviderProfile />
            </RoleBasedGuard>
          ),
        },
        {
          path: "provider-info",
          element: (
            <RoleBasedGuard hasContent roles={[Role.EnergyProvider]}>
              <PageProviderInfo />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: `Sign in`,
  login_header: "Smart Charging. Seamless Management. Greener Tomorrow.",
  register: `Sign up`,
  register_success:
    "Registration successful. Please check your email to activate your account.",
  register_header:
    "Sign up today to unlock new opportunities in the energy sector",
  forgot_password_success:
    "An email has been sent to the address associated with your account.",
  general: {
    edit: `Edit`,
    view: `View`,
    archive: `Archive`,
    unarchive: `Unarchive`,
    button_archive: `Yes, Archive`,
    button_unarchive: `Yes, Unarchive`,
    archive_message: `Are you sure want to archive {{length}} items?`,
    unarchive_message: `Are you sure want to unarchive {{length}} items?`,
    archive_one_message: `Are you sure want to archive {{name}}?`,
    unarchive_one_message: `Are you sure want to unarchive {{name}}?`,
    archived_success: `Archived successfully!`,
    unarchive_success: `Unarchived successfully!`,
    accept: `Accept`,
    confirm: `Confirm`,
  },
  form: {
    general: {
      save_changes: `Save changes`,
      create_success: `Create success!`,
      update_success: `Update success!`,
      something_wrong: `Something went wrong!`,
    },
  },
  infrastructure: {
    nav_title: `Infrastructure`,
  },
  // Simple user translations
  requests: {
    title: `Keon Trace`,
    nav_title: `Requests`,
    list: `View all`,
    archived: `Archived`,
    create: `New request`,
    create_title: `Create new request`,
    new_request: `New request`,
    update_title: `Update request`,
    request: `Request`,
    form: {
      title: `Title`,
      numRequests: `No. of certs.`,
      location: `Location`,
      organization: "Organization",
      openUntil: `Request's expiration date`,
      preferredTechnologies: `Preferred technologies`,
      preferredCountries: `Preferred countries`,
      provider: `My provider`,
      consumptionFrom: `From`,
      consumptionTo: `To`,
      productionDateFrom: `Certificates Production Period`,
      submit: `Create request`,
    },
    accept_offer_title: `Accept offer`,
    accept_message: `Are you sure want to accept this offer?`,
    offer_accepted: `The selected offer has been accepted successfully!`,
    archive: {
      title: "Archive Request",
      prompt: `Are you sure want to archive the request with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Request",
      prompt: `Are you sure want to unarchive the request with ID "{{id}}"?`,
    },
  },
  assets: {
    title: `Assets`,
  },
  organizations: {
    title: `Organizations`,
    nav_title: `Organizations`,
    list: `View all`,
    archived: `Archived`,
    create: `Create organization`,
    create_title: `Create new organization`,
    new_organization: `Create organization`,
    update_title: `Edit organization`,
    view_title: `View organization`,
    organization: `Organization`,
    form: {
      name: `Organization name`,
      vat: `VAT Number`,
      sector: `Sector`,
      address: `Address`,
      city: `City`,
      country: `Country`,
      zipCode: `Zip code`,
      provider: `Provider`,
      submit: `Create organization`,
    },
    archive: {
      title: "Archive Organization",
      prompt: `Are you sure want to archive the organization "{{name}}"?`,
    },
    unarchive: {
      title: "Unarchive Organization",
      prompt: `Are you sure want to unarchive the organization "{{name}}"?`,
    },
  },
  organization: {
    profile: {
      nav_title: "Organization",
      title: "Profile",
      public: "Public profile",
      view: "Public profile",
    },
  },
  locations: {
    title: `Locations`,
    nav_title: `Locations`,
    list: `View all`,
    archived: `Archived`,
    create: `Add Location`,
    create_title: `Create new location`,
    new_location: `Add Location`,
    update_title: `Edit location`,
    request: `Location`,
    form: {
      name: `Name`,
      provider: `Provider`,
      address: `Address`,
      city: `City`,
      country: `Country`,
      postCode: `Zip code`,
      type: `Location type`,
      powerSupply: `Power Supply Number`,
      lat: `Latitude`,
      lon: `Longitude`,
      submit: `Add location`,
    },
    archive: {
      title: "Archive Location",
      prompt: `Are you sure want to archive the location "{{name}}"?`,
    },
    unarchive: {
      title: "Unarchive Location",
      prompt: `Are you sure want to unarchive the location "{{name}}"?`,
    },
  },
  certificates: {
    title: `Energy Certificates`,
    nav_title: `Certificates`,
    list: `View all`,
    archived: `Archived`,
    create: `Upload certificate`,
    create_title: `Upload certificate`,
    new_certificate: `Upload certificate`,
    update_title: `Update certificate`,
    request: `Certificate`,
    form: {
      organization: `Organization`,
      location: `Organization location`,

      identificationNumber: "Production Device Identification Number",
      goLocation: "Park location",
      capacity: "Installed Capacity",
      technology: "Technology",
      commissioningDate: "Commissioning Date",
      operator: "Operator of the Production Device",
      supportScheme: "Support Scheme",
      withdrawalDate: "Date of Withdrawal",
      amount: "Amount of certificates",
      productionStart: "Starting Date of Production Period",
      productionEnd: "Ending Date of Production Period",
      consumer: "Consumer",

      submit: `Upload certificate`,
    },
  },
  // Provider translations
  providerCertificates: {
    title: `Energy Certificates`,
    nav_title: `Certificates`,
    list: `View all`,
    archived: `Archived`,
    create: `Send certificate`,
    create_title: `Send certificate`,
    new_providerCertificate: `Send certificate`,
    update_title: `Update certificate`,
    request: `Certificate`,
    form: {
      vat: `VAT Number`,
      powerSupplyNumber: `Power Supply Number`,

      identificationNumber: "Production Device Identification Number",
      goLocation: "Park location",
      capacity: "Installed Capacity",
      technology: "Technology",
      commissioningDate: "Commissioning Date",
      operator: "Operator of the Production Device",
      supportScheme: "Support Scheme",
      withdrawalDate: "Date of Withdrawal",
      amount: "Amount of certificates",
      productionStart: "Starting Date of Production Period",
      productionEnd: "Ending Date of Production Period",
      consumer: "Consumer",

      submit: `Send certificate`,
    },
  },
  providerRequests: {
    nav_title: `Requests`,
    list: `View all`,
    archived: `Archived`,
    request: `Certificate`,
    send_offer: `Send offer`,
    edit_offer: `Edit offer`,
    recall_offer: `Recall offer`,
    send_pdf: `Send PDF`,
    offer_sent: `An offer has been sent successfully!`,
    offer_edited: `The offer has been edited successfully!`,
    recalled_offer: `Your offer has been recalled successfully!`,
    recall_one_message: `Are you sure want to recall offer for {{name}}?`,
  },
  leads: {
    title: `Organizations`,
    nav_title: `Leads`,
    list: `View all`,
    invite: {
      invitationSuccess: "Your invitation has been sent successfully.",
    },
  },
  orders: {
    title: `Marketplace`,
    nav_title: `My orders`,
    logs: `Order logs`,
    completed: `Completed orders`,
    list: `View all`,
    archived: `Archived`,
    create: `New sell order`,
    create_sell_title: `Create new sell order`,
    create_buy_title: `Create new buy order`,
    new_sell_order: `New sell order`,
    new_buy_order: `New buy order`,
    order: `Sell order`,
    send_offer: `Send offer`,
    buy: `Buy`,
    form: {
      sell: {
        numRequests: `No. of certs.`,
        openUntil: `Order expiration date`,
        preferredCountry: `Country (Certs. origin)`,
        preferredTechnology: `Certs. Technology`,
        productionDateFrom: `Certs. Production date`,
        price: `Cost per certificate`,
      },
      buy: {
        numRequests: `No. of certs.`,
        openUntil: `Order expiration date`,
        preferredCountry: `Preferred Country (Certs. origin)`,
        preferredTechnology: `Preferred Certs. Technology`,
        productionDateFrom: `Preferred Certs. Production date `,
        price: `Cost per certificate`,
      },
      submit: `Create order`,
    },
    offer_accepted: `The selected offer has been accepted successfully!`,
    archive: {
      title: "Archive Request",
      prompt: `Are you sure want to archive the order with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Request",
      prompt: `Are you sure want to unarchive the order with ID "{{id}}"?`,
    },
    buy_offer_confirmation_title: "Buy Order",
    buy_offer_confirmation_content: `Are you sure want to buy the order with ID "{{id}}"?`,
    offer_buy_completed:
      "The process of buying the selected order has been completed successfully!",
  },
  spaces: {
    title: `Spaces`,
    nav_title: `Integrations`,
    list: `View all`,
    archived: `Archived`,
    create: `New space`,
    create_title: `Add new {{assetName}}`,
    new_space: `New {{assetName}}`,
    update_title: `Update asset`,
    space: `Space`,
    form: {
      name: "Name",
      description: "Description",
      organizationId: "Organization",
      locationId: "Location",
      type: "Data source",
      integrationType: "Integration type",
      submit: `Create asset`,
      linkAsset: `Link asset`,
    },
    archive: {
      title: "Archive Space",
      prompt: `Are you sure want to archive the space with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Space",
      prompt: `Are you sure want to unarchive the space with ID "{{id}}"?`,
    },
  },
  meters: {
    title: `Meters`,
    nav_title: `Meters`,
    list: `View all`,
    archived: `Archived`,
    create: `New meter`,
    create_title: `Create new meter`,
    new_meter: `New meter`,
    update_title: `Update meter`,
    view_title: "View meter",
    meter: `Meter`,
    form: {
      //  Todo
      submit: `Create meter`,
    },
    archive: {
      title: "Archive Meter",
      prompt: `Are you sure want to archive the meter with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Meter",
      prompt: `Are you sure want to unarchive the meter with ID "{{id}}"?`,
    },
  },
  pvs: {
    title: `Solar PVs`,
    nav_title: `Solar PVs`,
    list: `View all`,
    archived: `Archived`,
    create: `New Solar PV`,
    create_title: `Create new Solar PV`,
    new_pv: `New Solar PV`,
    update_title: `Update Solar PV`,
    view_title: "View Solar PV",
    pv: `Solar PV`,
    form: {
      //  Todo
      submit: `Create Solar PV`,
    },
    archive: {
      title: "Archive Solar PV",
      prompt: `Are you sure want to archive the Solar PV with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive PV",
      prompt: `Are you sure want to unarchive the Solar PV with ID "{{id}}"?`,
    },
  },
  vehicles: {
    title: `Electric Vehicles`,
    nav_title: `Electric Vehicles`,
    list: `View all`,
    archived: `Archived`,
    create: `New Electric Vehicle`,
    create_title: `Create new Electric Vehicle`,
    new_vehicle: `New Electric Vehicle`,
    update_title: `Update Electric Vehicle`,
    vehicle: `Electric Vehicle`,
    view_title: "View Electric Vehicle",
    form: {
      name: "Name",
      brand: "Brand",
      model: "Model",
      vin: "VIN",
      isCharging: "Charging mode",
      submit: `Create Electric Vehicle`,
    },
    archive: {
      title: "Archive Electric Vehicle",
      prompt: `Are you sure want to archive the Electric Vehicle with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Electric Vehicle",
      prompt: `Are you sure want to unarchive the Electric Vehicle with ID "{{id}}"?`,
    },
  },
  chargers: {
    title: `Chargers`,
    nav_title: `Chargers`,
    list: `View all`,
    archived: `Archived`,
    create: `New charger`,
    create_title: `Create new charger`,
    new_charger: `New charger`,
    update_title: `Update charger`,
    charger: `Charger`,
    view_title: "View charger",
    form: {
      name: "Name",
      // todo
      submit: `Create charger`,
    },
    archive: {
      title: "Archive Charger",
      prompt: `Are you sure want to archive the charger with ID "{{id}}"?`,
    },
    unarchive: {
      title: "Unarchive Charger",
      prompt: `Are you sure want to unarchive the charger with ID "{{id}}"?`,
    },
  },
  // Public charger
  publicChargers: {
    title: `Public Chargers`,
    nav_title: `Public Chargers`,
    list: `View all`,
  },
  // Trips
  trips: {
    title: `Trips`,
    nav_title: `Trips`,
    plan: `Planner`,
  },
  // General
  reports: {
    nav_title: `General`,
    dashboard: `Dashboard`,
  },
  roles: {
    role_standard: `Standard user`,
    role_provider: `Provider`,
    role_superadmin: `Super administrator`,
  },
  settings: {
    title: `Settings`,
    profile: `Profile`,
  },
  providerSettings: {
    title: `Settings`,
    profile: `Profile`,
    profileInfo: `Provider profile`,
  },

  // todo: remove
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  banking: `banking`,
  booking: `booking`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  error_code: {
    "1000000": "Please fill out all required fields",
    "1000002": "Email format is not valid",
    "1000003": "User already exists",
    "1000004": "Password is not strong enough",
    "1000005": "User already verified",
    "1000006": "Code is expired",
    "1000007": "User email is not verified",
    "1000008": "Forgot password expired",
    "1000009": "User must accept terms of use",
    "1000010": "Image has not got a valid extension",
    "1000011": "Image is required",
    "1000012": "VAT already in use from an organization created by this user",
    "1000013": "You must accept the confirmation",
    "1000014": "Form data is not valid JSON",
    "1000015": "Organization not found",
    "1000016": "You cannot update an organization that you did not create",
    "1000017": "Geolocation must have valid lat and lon",
    "1000018": "Location not found",
    "1000019": "You cannot get a location that you did not create",
    "1000020": "Provider's VAT cannot be changed",
    "1000021": "VAT already exists in an other provider",
    "1000022":
      "Invalid credentials. Please check your email address and password and try again.",
    "1000023": "Provider type must be valid",
    "1000024": "User already added to provider",
    "1000025": "Provider does not exist",
    "1000026": "User does not exist",
    "1000027": "User is not provider",
    // "1000028": "$field must be an array",
    // "1000029": "$field must be a date",
    // "1000030": "$field must be a number",
    "1000031": "Consumption To must be a date subsequent to consumption From",
    "1000032": "Request not found or does not belong to the user",
    "1000033": "Request already archived",
    "1000034": "Cannot archive a request that is not in pending status.",
    "1000035": "CountryCode or Organization Id must be provided",
    "1000036": "Offer not found",
    "1000037": "Request is not in valid state for this action",
    "1000038": "Provider is not permitted to offer to this request",
    "1000039": "Provider not permitted to access request",
    "1000040": "Offer is not accepted/can't access request",
    "1000041": "VAT does not match request organization",
    "1000042": "Order type must be buy or sell",
    "1000043":
      "Cost per certificate must be a positive number and greater than zero",
    "1000044": "Order not found",
    "1000045": "Order not belong to the provider",
    "1000046": "Order is not in valid state for this action",
    "1000047": "Field must be greater than 0",
    "1000048": "Offer exists to this order by this provider",
    "1000049": "Location does not belong to organization",
    "1000050": "Invalid Space data type",
    "1000051": "Invalid source",
    "1000052": "Space not found",
    "1000053": "A space with the same name already exists in this organization",

    "9000000": "Something went wrong",
  },
};

export default en;

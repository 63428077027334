import { Suspense, lazy, ElementType } from "react";
// components

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    // <Suspense fallback={<LoadingScreen />}>
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const RegisterPage = Loadable(
  lazy(() => import("../pages/RegisterPage"))
);
export const ForgotPasswordPage = Loadable(
  lazy(() => import("../pages/ForgotPasswordPage"))
);
export const ChangePasswordPage = Loadable(
  lazy(() => import("../pages/ChangePasswordPage"))
);

// Requests
export const RequestsAllPage = Loadable(
  lazy(() => import("../pages/requests/RequestsAllPage"))
);
export const RequestsAddPage = Loadable(
  lazy(() => import("../pages/requests/RequestsAddPage"))
);
export const RequestViewPage = Loadable(
  lazy(() => import("../pages/requests/RequestViewPage"))
);

// Organizations
export const OrganizationsAllPage = Loadable(
  lazy(() => import("../pages/organizations/OrganizationsAllPage"))
);
export const OrganizationsAddPage = Loadable(
  lazy(() => import("../pages/organizations/OrganizationsAddPage"))
);
export const OrganizationsEditPage = Loadable(
  lazy(() => import("../pages/organizations/OrganizationsEditPage"))
);
export const OrganizationProfile = Loadable(
  lazy(() => import("../pages/organizations/profile/OrganizationProfile"))
);
export const OrganizationViewPage = Loadable(
  lazy(() => import("../pages/organizations/OrganizationViewPage"))
);

// Locations
export const LocationsAll = Loadable(
  lazy(() => import("../pages/locations/LocationsAllPage"))
);
export const LocationsAddPage = Loadable(
  lazy(() => import("../pages/locations/LocationsAddPage"))
);
export const LocationsEditPage = Loadable(
  lazy(() => import("../pages/locations/LocationsEditPage"))
);
export const LocationViewPage = Loadable(
  lazy(() => import("../pages/locations/LocationDetailsPage"))
);

// Reports
export const DashboardPage = Loadable(
  lazy(() => import("../pages/dashboard/DashboardPage"))
);
export const ProviderDashboardPage = Loadable(
  lazy(() => import("../pages/dashboard/ProviderDashboardPage"))
);

// Profile
export const PageProfile = Loadable(
  lazy(() => import("../pages/account/UserAccountPage"))
);

// Certificates
export const CertificatesAllPage = Loadable(
  lazy(() => import("../pages/certificates/CertificatesAllPage"))
);
export const CertificatesAddPage = Loadable(
  lazy(() => import("../pages/certificates/CertificatesAddPage"))
);

// Provider certificates
export const ProviderCertificatesAllPage = Loadable(
  lazy(
    () => import("../pages/providerCertificates/ProviderCertificatesAllPage")
  )
);
export const ProviderCertificatesAddPage = Loadable(
  lazy(
    () => import("../pages/providerCertificates/ProviderCertificatesAddPage")
  )
);

// Provider profile
export const PageProviderProfile = Loadable(
  lazy(() => import("../pages/providerAccount/ProviderAccountPage"))
);
export const PageProviderInfo = Loadable(
  lazy(() => import("../pages/providerAccount/ProviderInfoPage"))
);

// Provider requests
export const ProviderRequestsAllPage = Loadable(
  lazy(() => import("../pages/providerRequests/ProviderRequestsAllPage"))
);
export const ProviderRequestViewPage = Loadable(
  lazy(() => import("../pages/providerRequests/ProviderRequestViewPage"))
);

// Orders
export const SellOrderPage = Loadable(
  lazy(() => import("../pages/orders/sellOrder/SellOrderAddPage"))
);
export const BuyOrderPage = Loadable(
  lazy(() => import("../pages/orders/buyOrder/BuyOrderAddPage"))
);
export const OrdersPage = Loadable(
  lazy(() => import("../pages/orders/OrdersPage"))
);
export const OrderLogsPage = Loadable(
  lazy(() => import("../pages/orderLogs/OrderLogsPage"))
);
export const OrdersCompletedPage = Loadable(
  lazy(() => import("../pages/orderLogs/OrdersAllCompletedPage"))
);

// Spaces
export const SpacesAllPage = Loadable(
  lazy(() => import("../pages/spaces/SpacesAllPage"))
);
export const SpacesAddPage = Loadable(
  lazy(() => import("../pages/spaces/SpacesAddPage"))
);
export const SpacesEditPage = Loadable(
  lazy(() => import("../pages/spaces/SpacesEditPage"))
);
export const SpaceViewPage = Loadable(
  lazy(() => import("../pages/spaces/SpaceViewPage"))
);
export const SpaceAddSuccessPage = Loadable(
  lazy(() => import("../pages/spaces/SpaceAddSuccess"))
);
export const SpaceAddErrorPage = Loadable(
  lazy(() => import("../pages/spaces/SpaceAddError"))
);

// Vehicles
export const VehicleAllPage = Loadable(
  lazy(() => import("../pages/vehicles/VehiclesAllPage"))
);
// export const VehicleAddPage = Loadable(
//   lazy(() => import("../pages/vehicles/VehiclesAddPage"))
// );
// export const VehicleEditPage = Loadable(
//   lazy(() => import("../pages/vehicles/VehiclesEditPage"))
// );
export const VehicleViewPage = Loadable(
  lazy(() => import("../pages/vehicles/VehicleViewPage"))
);
export const VehicleDetailsPage = Loadable(
  lazy(() => import("../pages/vehicles/VehicleDetailsPage"))
);

// Chargers
export const ChargerAllPage = Loadable(
  lazy(() => import("../pages/chargers/ChargersAllPage"))
);
// export const ChargerAddPage = Loadable(
//   lazy(() => import("../pages/chargers/ChargersAddPage"))
// );
// export const ChargerEditPage = Loadable(
//   lazy(() => import("../pages/chargers/ChargersEditPage"))
// );
export const ChargerViewPage = Loadable(
  lazy(() => import("../pages/chargers/ChargerViewPage"))
);
export const ChargerDetailsPage = Loadable(
  lazy(() => import("../pages/chargers/ChargerDetailsPage"))
);

// Meters
export const MeterAllPage = Loadable(
  lazy(() => import("../pages/meters/MetersAllPage"))
);
export const MeterDetailsPage = Loadable(
  lazy(() => import("../pages/meters/MeterDetailsPage"))
);

// PVs
export const PVAllPage = Loadable(
  lazy(() => import("../pages/pvs/PVsAllPage"))
);
export const PVDetailsPage = Loadable(
  lazy(() => import("../pages/pvs/PVDetailsPage"))
);

// Public chargers
export const PublicChargersAllPage = Loadable(
  lazy(() => import("../pages/publicChargers/PublicChargersAllPage"))
);

// Leads
export const LeadsPage = Loadable(
  lazy(() => import("../pages/leads/LeadsPage"))
);

// File manager
export const FileManagerPage = Loadable(
  lazy(() => import("../pages/fileManager/FileManagerPage"))
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));

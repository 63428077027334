import { useCallback } from "react";
import axios from "src/utils/axios";

export function usePublicChargersGetAll() {
  const markersGetAll = useCallback(async () => {
    let path = `assets/evses/mapMarkers`;

    return axios
      .get(path)
      .then((response) => {
        return response.data;
      })
      .catch((err) => err);
  }, []);

  return {
    markersGetAll,
  };
}

export function usePublicChargerById() {
  const fetchPublicCharger = useCallback(
    async ({ evseId }: { evseId: string }) => {
      let path = `assets/evses/${evseId}/details`;

      return axios
        .get(path)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    },
    []
  );

  return { fetchPublicCharger };
}

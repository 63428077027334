// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
// date-fns
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from "react-redux";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from "./auth/JwtContext";
import { store } from "./redux/store";
// import { LoadScript } from "@react-google-maps/api";
import config, {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_API_LOCALHOST_KEY,
} from "./config";
import { APIProvider } from "@vis.gl/react-google-maps";

// ----------------------------------------------------------------------

const isLocalhost = config.SERVICE.isLocalhost;

export default function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <HelmetProvider>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <APIProvider
                            apiKey={
                              isLocalhost
                                ? GOOGLE_MAPS_API_LOCALHOST_KEY
                                : GOOGLE_MAPS_API_KEY
                            }
                          >
                            {/* <LoadScript
                            googleMapsApiKey={
                              isLocalhost
                                ? GOOGLE_MAPS_API_LOCALHOST_KEY
                                : GOOGLE_MAPS_API_KEY
                            }
                          > */}
                            <Router />
                            {/* </LoadScript> */}
                          </APIProvider>
                        </LocalizationProvider>
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </HelmetProvider>
      </AuthProvider>
    </Provider>
  );
}

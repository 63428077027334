// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";
import { Role } from "src/enum/roles";
import _ from "lodash";
import config from "src/config";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  requests: icon("ic_requests"),
  certificates: icon("ic_certificates"),
  addRequest: icon("ic_addRequest"),

  organizations: icon("ic_organization"),
  addOrganization: icon("ic_addRequest"),

  user: icon("ic_user"),
  books: icon("ic_ecommerce"),
  categories: icon("ic_analytics"),
  videoCategories: icon("ic_analytics"),
  authors: icon("ic_analytics"),
  publishers: icon("ic_analytics"),
  videos: icon("ic_dashboard"),
  reports: icon("ic_dashboard"),
  creators: icon("ic_analytics"),
  producers: icon("ic_analytics"),

  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  providerInfo: icon("ic_provider"),

  dashboard: icon("dashboard"),

  orders: icon("orders"),
  ordersLogs: icon("order-logs"),
  ordersCompleted: icon("order-completed"),

  leads: icon("leads"),

  spaces: icon("spaces"),
  vehicles: icon("vehicle"),
  meters: icon("meter"),
  pvs: icon("sun"),
  chargingStation: icon("chargingStation"),
  spacesAnalytics: icon("space-analytics"),
  locationMark: icon("location"),
};

const navConfig = _.compact([
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "reports.nav_title",
    items: _.compact([
      {
        title: "reports.dashboard",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
        roles: [Role.StandardUser, Role.SuperAdmin],
      },
      {
        title: "reports.dashboard",
        path: PATH_DASHBOARD.general.providerDashboard,
        icon: ICONS.dashboard,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
    ]),
  },

  // INFRASTRUCTURE
  // ----------------------------------------------------------------------
  {
    subheader: "infrastructure.nav_title",
    items: _.compact([
      {
        title: "organizations.nav_title",
        path: PATH_DASHBOARD.organizations.list,
        icon: ICONS.organizations,
        roles: [Role.StandardUser, Role.SuperAdmin],
      },
      {
        title: "locations.nav_title",
        path: PATH_DASHBOARD.locations.list,
        icon: ICONS.locationMark,
        roles: [Role.StandardUser, Role.SuperAdmin],
      },
    ]),
  },

  // ASSETS
  // ----------------------------------------------------------------------
  {
    subheader: "assets.title",
    items: _.compact([
      config.SERVICE.vehiclesEnabled
        ? {
            title: "vehicles.nav_title",
            path: PATH_DASHBOARD.vehicles.list,
            icon: ICONS.vehicles,
            roles: [Role.StandardUser, Role.SuperAdmin],
          }
        : undefined,
      config.SERVICE.metersEnabled
        ? {
            title: "meters.nav_title",
            path: PATH_DASHBOARD.meters.list,
            icon: ICONS.meters,
            roles: [Role.StandardUser, Role.SuperAdmin],
          }
        : undefined,
      config.SERVICE.pvsEnabled
        ? {
            title: "pvs.nav_title",
            path: PATH_DASHBOARD.pvs.list,
            icon: ICONS.pvs,
            roles: [Role.StandardUser, Role.SuperAdmin],
          }
        : undefined,
      config.SERVICE.chargersEnabled
        ? {
            title: "chargers.nav_title",
            path: PATH_DASHBOARD.chargers.root,
            icon: ICONS.chargingStation,
            roles: [Role.StandardUser, Role.SuperAdmin],
            children: [
              {
                title: "chargers.nav_title",
                path: PATH_DASHBOARD.chargers.list,
                // icon: ICONS.chargingStation,
                roles: [Role.StandardUser, Role.SuperAdmin],
              },
              {
                title: "publicChargers.nav_title",
                path: PATH_DASHBOARD["public-chargers"].list,
                // icon: ICONS.chargingStation,
                roles: [Role.StandardUser, Role.SuperAdmin],
              },
            ],
          }
        : undefined,
    ]),
  },

  // REQUESTS
  // ----------------------------------------------------------------------
  // {
  //   subheader: "requests.title",
  //   items: [
  //     {
  //       title: "requests.nav_title",
  //       path: PATH_DASHBOARD.requests.list,
  //       icon: ICONS.requests,
  //       roles: [Role.StandardUser, Role.SuperAdmin],
  //     },
  //     {
  //       title: "certificates.nav_title",
  //       path: PATH_DASHBOARD.certificates.list,
  //       icon: ICONS.certificates,
  //       roles: [Role.StandardUser, Role.SuperAdmin],
  //     },
  //   ],
  // },

  // PROVIDER CERTIFICATES & REQUESTS
  // ----------------------------------------------------------------------
  {
    subheader: "providerCertificates.title",
    items: [
      {
        title: "providerRequests.nav_title",
        path: PATH_DASHBOARD["provider-requests"].list,
        icon: ICONS.requests,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
      {
        title: "providerCertificates.nav_title",
        path: PATH_DASHBOARD["provider-certificates"].list,
        icon: ICONS.certificates,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
      {
        title: "providerCertificates.create",
        path: PATH_DASHBOARD["provider-certificates"].create,
        icon: ICONS.addRequest,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
    ],
  },

  // LEADS
  // ----------------------------------------------------------------------
  {
    subheader: "leads.title",
    items: [
      {
        title: "leads.nav_title",
        path: PATH_DASHBOARD.leads.list,
        icon: ICONS.leads,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
    ],
  },

  // ORDERS
  // ----------------------------------------------------------------------
  {
    subheader: "orders.title",
    items: [
      {
        title: "orders.nav_title",
        path: PATH_DASHBOARD.orders.list,
        icon: ICONS.orders,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
      {
        title: "orders.logs",
        path: PATH_DASHBOARD.orders.logs,
        icon: ICONS.ordersLogs,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
      {
        title: "orders.completed",
        path: PATH_DASHBOARD.orders.completed,
        icon: ICONS.ordersCompleted,
        roles: [Role.EnergyProvider, Role.SuperAdmin],
      },
    ],
  },

  // PROFILE (SIMPLE USER / SUPER ADMIN)
  // ----------------------------------------------------------------------
  {
    subheader: "settings.title",
    items: _.compact([
      {
        title: "settings.profile",
        path: PATH_DASHBOARD.profile,
        icon: ICONS.user,
        roles: [Role.StandardUser, Role.SuperAdmin],
      },
      config.SERVICE.spacesEnabled
        ? {
            title: "spaces.nav_title",
            path: PATH_DASHBOARD.spaces.list,
            icon: ICONS.spaces,
            roles: [Role.StandardUser, Role.SuperAdmin],
          }
        : undefined,
    ]),
  },

  // PROVIDER PROFILE
  // ----------------------------------------------------------------------
  {
    subheader: "providerSettings.title",
    items: [
      {
        title: "providerSettings.profile",
        path: PATH_DASHBOARD["provider-profile"],
        icon: ICONS.user,
        roles: [Role.EnergyProvider],
      },
      {
        title: "providerSettings.profileInfo",
        path: PATH_DASHBOARD["provider-info"],
        icon: ICONS.providerInfo,
        roles: [Role.EnergyProvider],
      },
    ],
  },
]);

export default navConfig;

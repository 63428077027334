// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { usePublicChargersGetAll } from "src/hooks/publicChargers/usePublicChargersService";

interface State {
  isLoading: boolean;
  error: any;
  entries: any[];
}

const initialState: State = {
  isLoading: false,
  error: null,
  entries: [],
};

export const publicChargerSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ENTITY VALUES
    getPublicChargerSuccess(state, action) {
      state.isLoading = false;
      state.entries = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = publicChargerSlice.actions;

export default publicChargerSlice.reducer;

// ----------------------------------------------------------------------

export function getPublicChargers() {
  return async (dispatch: Dispatch) => {
    dispatch(publicChargerSlice.actions.startLoading());

    const { markersGetAll } = usePublicChargersGetAll();

    try {
      const response = await markersGetAll();

      if (!_.isNil(response?.markers)) {
        dispatch(
          publicChargerSlice.actions.getPublicChargerSuccess(response.markers)
        );
      }
    } catch (error) {
      dispatch(publicChargerSlice.actions.hasError(error));
    }
  };
}

// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from "@reduxjs/toolkit";
import organizationReducer from "./slices/organization";
import energyProviderReducer from "./slices/energyProvider";
import publicChargerReducer from "./slices/publicCharger";

export const store = configureStore({
  reducer: {
    organization: organizationReducer,
    energyProvider: energyProviderReducer,
    publicCharger: publicChargerReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
